const app = {
  init: function () {
    this.initMobileNav();
  },

  initMobileNav: function () {
    const body = document.querySelector("body");
    const btn = document.getElementById("mobile-nav-btn");

    if (btn) {
      btn.addEventListener("click", function (e) {
        e.preventDefault();

        if (body) {
          body.classList.toggle("menuIsOpen");
        }
      });
    }
  },
};

app.init();
